import React from "react";
import { ListCard } from "../../components/ListCard";
import { galleries } from "../../data/gallery";

export const Gallery: React.FC = () => {
  return (
    <div className="w-full flex items-center flex-col relative pt-8 pb-8 ">
      <ListCard link="gallery" cards={galleries.sort((a, b) => b.id - a.id)} />
    </div>
  );
};
