import React, { useEffect, useState } from "react";
import { therapistList } from "../../../data/therapists";
import { useNavigate, useParams } from "react-router-dom";
import { Therapist } from "../types";

export const TherapistDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState<Therapist | null>(null);

  useEffect(() => {
    const newTherapist = therapistList.find(
      (therapist) => id && therapist.id === +id
    );
    if (!newTherapist) {
      navigate(-1);
      return;
    }

    setTherapist(newTherapist);
  }, [id, navigate]);

  if (!therapist) {
    return null;
  }

  return (
    <div className={`flex flex-col items-center py-8`}>
      <img
        src={therapist.image}
        alt={therapist.name}
        className="rounded-full w-48 h-48"
      />
      <h3 className="py-6 font-semibold text-lg lg:text-xl">
        {therapist.name}
      </h3>
      {therapist.description}
    </div>
  );
};
