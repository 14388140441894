import React from "react";
import { MOBILE_PADDING } from "../../shared/styles";
import { Link } from "react-router-dom";
import { therapistList } from "../../data/therapists";

export const Therapists: React.FC = () => {
  return (
    <div className={`flex justify-center`}>
      <div
        className={`grid grid-cols-2 lg:grid-cols-3 gap-6 ${MOBILE_PADDING} my-12`}
      >
        {therapistList.map(({ id, image, name, grade }) => (
          <Link
            key={id}
            to={`/therapist/${id}`}
            className="lg:flex lg:items-center lg:flex-col lg:px-12"
          >
            <img src={image} alt={name} className="rounded-full lg:w-48" />
            <h3 className="text-lg text-center text-main-500 lg:mt-6 mt-2">
              {name}
            </h3>
            <p className="text-xs text-center text-main-400">상세보기</p>
            <p className="text-center text-xs lg:mt-2 mt-1 text-main-300">
              {grade}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};
