import React, { FC } from "react";
import Calendar from "../../assets/icon/calendar.png";

const ReserveButton: FC = () => {
  return (
    <div
      className="fixed right-4 bottom-16 z-10
    rounded-full p-4 flex justify-center items-center flex-col cursor-pointer"
      style={{ backgroundColor: "#03c75a" }}
      onClick={() => {
        window.open("https://m.booking.naver.com/booking/6/bizes/266076");
      }}
    >
      <img src={Calendar} alt="calendar" className="w-6 h-6 z-40" />
    </div>
  );
};

export default ReserveButton;
