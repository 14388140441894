import { Content } from "../types";
import interview1 from "../../assets/img/interview/interview1.jpeg";
import interview2 from "../../assets/img/interview/interview2.jpeg";
import interview3 from "../../assets/img/interview/interview3.jpeg";
import interview4 from "../../assets/img/interview/interview4.jpeg";
import interview5 from "../../assets/img/interview/interview5.jpeg";
import interview_b1 from "../../assets/img/interview/interview_b1.jpeg";
import interview_b2 from "../../assets/img/interview/interview_b2.jpeg";

export const interviews: Content[] = [
  {
    id: 24,
    title: "[도서 출간] 삐딱한 성장, 결혼 바로 세우기",
    description: `
      <p>자기성장과 결혼에 관한 내용을 담은 책을 출간하였습니다. <a href="http://www.kyobobook.co.kr/product/detailViewKor.laf?ejkGb=KOR&mallGb=KOR&barcode=9791185696669&orderClick=LAG&Kc=" style="color: #007aff">도서 링크</a></p>
      <br/>
      <h4 style="font-weight: bold">목차</h4>
      <div style="font-size: 14px">
        들어가는 말
        <br>
        <br>
        Ⅰ. 만남: 최초 대상의 중요성
        <br>
        
        <br>
        1. 최초의 대상 나의 엄마: 참 좋은 어머니를 만나는 축복
        <br>
        1) 첫 만남과 경험의 중요성
        <br>
        2) 믿음과 신뢰를 쌓은 초석
        <br>
        2. 오이디푸스기의 필요한 좌절: 외상이 되지 않는 적절한 좌절
        <br>
        1) 근친상간의 심리적 고리
        <br>
        2) 좌절을 겪는 아픔속의 성장
        <br>
        3. 대상들과의 만남: 세상을 향해 나아가야 한다
        <br>
        1) 의존에서 벗어나기
        <br>
        2) 세상으로 나아가는 자신감
        <br>
        4. 욕망의 나래: 진정 원하는 것은 무엇일까?
        <br>
        1) 질풍노도(疾風怒濤)의 시기
        <br>
        2) 고통속의 혼돈
        <br>
        5. 이상과 가치를 향한 삶: 본능을 넘어서는 목적의 삶
        <br>
        1) 자녀 인생에 손대지 말기
        <br>
        2) 내 안의 핵자기 실현
        <br>
        
        <br>
        Ⅱ. 결혼: 무의식이 찾은 내 배우자
        <br>
        
        <br>
        1. 내안의 익숙한 선택: 무의식과 관련되어 있는 내 배우자
        <br>
        1) 무의식 안의 내 역사
        <br>
        2) 결여와 결여의 만남
        <br>
        2. 환상과 현실: 꿈은 사라지고 현실만 남는다
        <br>
        1) 구원자 신드롬(messiah syndrome)
        <br>
        2) 어머니 같은 배우자는 없다
        <br>
        3. 분화하며 겪는 아픔: 주체로 나가는 성장의 시작
        <br>
        1) 나의 어머니 내려놓기
        <br>
        2) 사랑하는 배우자와의 분화
        <br>
        4. 받아들임과 회피: 고뇌의 열매와 부정감정 묶임의 방출
        <br>
        1) 마주하고 싶지 않은 원수
        <br>
        2) 고통을 견디는 승화
        <br>
        5. 언어와 몸의 대화: 부부를 이어주는 두 줄기
        <br>
        1) 육체(肉體)를 통한 섹스의 오르가즘(orgasm)
        <br>
        2) 언어(言語)를 통한 대화의 환희(歡喜)
        <br>
        
        <br>
        Ⅲ. 이혼: 나의 한계는 여기까지
        <br>
        
        <br>
        1. 실망과 절망: 만족을 모르면 절망만 가슴에 안는다
        <br>
        1) 만족이 없는 행복
        <br>
        2) 희망이 없는 절망
        <br>
        2. 탓, 너 때문이야: 자유에는 책임이 따르게 된다
        <br>
        1) 내 선택을 인정하고
        <br>
        2) 자유에 따르는 책임감
        <br>
        3. 욕망의 갈등: 무의식적 갈등과 현실과의 괴리감
        <br>
        1) 흩어지는 대상놀음
        <br>
        2) 에너지를 모아주는 채널
        <br>
        4. 분노와 증오의 폭발: 엉켜진 갈등의 말이 주는 상처
        <br>
        1) 쌓아놓은 억압의 분출
        <br>
        2) 남는 것이 없는 방출
        <br>
        5. 힘없는 회피의 결론: 힘든 것을 피하고 싶어 찾은 선택
        <br>
        1) 지옥을 벗어나고 싶어!
        <br>
        2) 피하는 결론의 반복
        <br>
        
        <br>
        Ⅳ. 재혼: 다시 만회(挽回)하고 싶은 내 인생
        <br>
        
        <br>
        1. 원 가족 회복의 꿈: 다시 찾고 싶은 무의식적 욕망
        <br>
        1) 혼자 살 수 없는 인생길
        <br>
        2) 다시 한 번 찾고 싶은 에덴동산
        <br>
        2. 꿈과 현실의 갈등: 더 큰 갈등으로 고갈되어가는 자아
        <br>
        1) 배우자를 구원자로 기대하지 말자
        <br>
        2) 고갈되어가는 자아
        <br>
        3. 얽혀진 넝쿨 몇 가정: 전 배우자와 현재의 가정은 얽혀 있다
        <br>
        1) 나와 내 아이
        <br>
        2) 우리와 우리아이
        <br>
        4. 받아들임과 인정: 서로를 긍휼이 여기는 마음
        <br>
        1) 서로의 상처를 안아줘야
        <br>
        2) 신뢰와 믿음 쌓아가기
        <br>
        5. 녹아내린 상처: 고통이 녹아 아물어 간다
        <br>
        1) 고통(Agony)의 터널을 지난 성숙
        <br>
        2) 인생이란 이런 것
        <br>
        
        <br>
        Ⅴ. 회복(recovery): 변화를 통한 성장
        <br>
        
        <br>
        1. 자기 분석을 받자: “너 자신을 알라!“
        <br>
        1) 억압된 감정의 정화
        <br>
        2) 정신에너지 구조 세우기
        <br>
        2. 나와 대상을 이해: 나를 알아 가면 대상을 이해한다
        <br>
        1) 내 안의 들보 남의 눈의 티
        <br>
        2) 상처의 치유와 관계 회복
        <br>
        3. 든든한 보상구조: 정체성을 획득한 활동
        <br>
        1) 욕망(慾望) 바라보기
        <br>
        2) 자아 욕구의 실현
        <br>
        4. 함께 꽃피우는 사회공동체: 아름다운 합력이 이루어 내는 선(善)
        <br>
        1) 법과 질서에 순응한 삶
        <br>
        2) 인격 성숙에 따르는 고통
        <br>
        5. 자기 부인으로 가는 성화 과정: 내가 죽어야 산다
        <br>
        1) 소명을 붙잡고 가는 삶
        <br>
        2) 성화의 원동력 희망
        <br>
        
        <br>
        저자 후기
        <br>
        참고문헌
    </div>
    `,
    imageUrl: interview5,
    createdAt: "2020-08-03 11:12:18",
  },
  {
    id: 25,
    title: "인터뷰와 기사모음",
    description: `
      <img src="${interview_b2}" alt="in_center1" style="margin-bottom: 10px" />
    `,
    imageUrl: interview_b1,
    createdAt: "2021-04-03 11:12:18",
  },
  {
    id: 23,
    title: 'MBC "생방송 오늘 아침"',
    description:
      "<p><!--StartFragment--></p><p>MBC &quot;생방송 오늘아침&quot; 에서 갑자기 전화가 왔다.</p><p>상담을 급하게 요청하여</p><p>내담자를 만나게 되고 우연찮게</p><p>방송을 진행하게 되었다.</p><p>&nbsp;</p><p>내용인즉,</p><p>물건이 자꾸 없어지고, 그 없어지는 물건들은 남편에게 다른 여자가 생겨서 그곳으로 물건을 나르게 되었던 것이었다.</p><p>바람난 남편~~!!!</p><p>&nbsp;</p><p>부부는 서로 리비도를 주고 받으며 살아가야만 한다.</p><p>그렇지 않으면 그 리비도를 다른곳에서 추구하려는 본능적인 마음이 생겨나기때문이다.</p><p>&nbsp;</p><p>&nbsp;</p>",
    imageUrl: interview1,
    createdAt: "2019-07-03 12:13:38",
  },
  {
    id: 21,
    title: "내담자에게 건강한 생명력을 불어 넣는다",
    description:
      '<p><a href="http://www.weeklypeople.co.kr/detail.php?number=3498" style="color: #007aff">http://www.weeklypeople.co.kr/detail.php?number=3498</a></p>',
    imageUrl: interview3,
    createdAt: "2019-07-03 11:24:52",
  },
  {
    id: 22,
    title: "온전한 자기성장에 주목하다",
    description:
      '<p><a href="http://www.weeklypeople.net/view.do?seq=13769" style="color: #007aff">http://www.weeklypeople.net/view.do?seq=13769</a></p>',
    imageUrl: interview2,
    createdAt: "2019-07-03 11:26:34",
  },
  {
    id: 20,
    title: "심리치료의 핵심은 곧 자기치료",
    description:
      '<p><a href="http://blog.naver.com/PostView.nhn?blogId=snufkinn&amp;logNo=220705619460" style="color: #007aff">http://blog.naver.com/PostView.nhn?blogId=snufkinn&amp;logNo=220705619460</a></p><p>&nbsp;</p><p>철저한 자기분석을 넘어서 진정한 치료자가 되기까지</p><p><strong>영혼의 상담사, 김여환 가정심리상담소장</strong></p><p>&nbsp;</p><p>&nbsp;</p>',
    imageUrl: interview4,
    createdAt: "2019-07-03 11:12:18",
  },
];
