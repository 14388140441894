import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { blogs } from "../../../data/blogs";
import { Content } from "../../../data/types";
import { toast } from "react-toastify";
import styled from "styled-components";

const YoutubeIFrameWrapper = styled.div``;

const YoutubeIframe = styled.div``;

// @ts-ignore
export const BlogDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<Content | null>(null);

  useEffect(() => {
    const blog = blogs.find((blog) => id && blog.id === +id);
    if (!blog) {
      toast.warning("찾을 수 없는 페이지입니다.");
      navigate("/");
      return;
    }

    setBlog(blog);
  }, [navigate, id]);

  if (!blog) {
    return null;
  }

  return (
    <div className="flex justify-center my-16">
      <div className="flex items-center flex-col lg:w-web px-4 lg:px-0">
        <h1 className="font-bold text-2xl mb-8">{blog.title}</h1>
        <YoutubeIFrameWrapper>
          {blog.youtubeUrl && (
            <YoutubeIframe
              dangerouslySetInnerHTML={{ __html: blog.youtubeUrl }}
            />
          )}
        </YoutubeIFrameWrapper>
        {blog.description && (
          <p
            dangerouslySetInnerHTML={{ __html: blog.description }}
            className="mt-8"
          />
        )}
      </div>
    </div>
  );
};
