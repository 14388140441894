import React from "react";
import Header from "../shared/components/Header";
import Banner from "../shared/components/Banner";
import { Routes, Route } from "react-router-dom";
import Home from "./home";
import Footer from "../shared/components/Footer";
import ReserveButton from "../shared/components/ReserveButton";
import { TherapistDetail } from "./Therapists/detail";
import { Interviews } from "./interviews";
import { InterviewDetail } from "./interviews/detail";
import { Blogs } from "./blogs";
import { BlogDetail } from "./blogs/detail";
import { Therapists } from "./Therapists";
import { GalleryDetail } from "./galleries/detail";
import { Gallery } from "./galleries";
import { PageNotFound } from "./PageNotFound";

const Root: React.FC = () => {
  return (
    <>
      <Header />
      <Banner />
      <ReserveButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/therapist" element={<Therapists />} />
        <Route path="/therapist/:id" element={<TherapistDetail />} />
        <Route path="/interview" element={<Interviews />} />
        <Route path="/interview/:id" element={<InterviewDetail />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<GalleryDetail />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Root;
