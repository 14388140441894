import React from "react";
import { Link } from "react-router-dom";
import { Content } from "../data/types";
import DefaultImage from "../assets/img/default_card.jpeg";
import moment from "moment";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

interface Props {
  link: "blog" | "interview" | "gallery";
  cards: Content[];
}

const getDateFormat = (value: Date | string) => {
  const date = moment(value);

  return (
    <div
      className="absolute right-2 top-2 rounded-full z-10
              bg-main-400 flex flex-col w-10 h-10 items-center pt-1"
    >
      <span className="text-white text-12">{months[date.month()]},</span>
      <span className="text-white text-12">{date.date()}</span>
    </div>
  );
};

export const ListCard: React.FC<Props> = ({ link, cards }) => {
  return (
    <div className="grid lg:grid-cols-3 gap-8 lg:gap-4 lg:w-web w-full grid-cols-1">
      {cards.map((card) => (
        <Link
          to={`/${link}/${card.id}`}
          key={`list-card-${link}-${card.id}`}
          className="relative w-full lg:mr-4 cursor-pointer"
        >
          <img
            src={card.imageUrl}
            className="object-cover w-full lg:rounded h-48"
            alt={`${card.id}`}
            onError={(e) => {
              e.currentTarget.src = DefaultImage;
              e.currentTarget.onerror = null;
            }}
          />
          <h1 className="font-bold text-16 mt-2 text-center">{card.title}</h1>
          {getDateFormat(card.createdAt)}
        </Link>
      ))}
    </div>
  );
};
