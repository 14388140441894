import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "../../../data/types";
import { interviews } from "../../../data/interviews";
import { toast } from "react-toastify";

export const InterviewDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [interview, setInterview] = useState<Content | null>(null);

  useEffect(() => {
    const newInterview = interviews.find(
      (interview) => id && interview.id === +id
    );
    if (!newInterview) {
      toast.warning("찾을 수 없는 페이지입니다.");
      navigate("/");
      return;
    }

    setInterview(newInterview);
  }, [id, navigate]);

  if (!interview) {
    return null;
  }

  return (
    <div className="flex justify-center my-16">
      <div className="flex items-center flex-col lg:w-web px-4 lg:px-0">
        <h1 className="font-bold text-2xl mb-8">{interview.title}</h1>
        <img src={interview.imageUrl} alt="gallery" />
        {interview.description && (
          <p
            dangerouslySetInnerHTML={{ __html: interview.description }}
            className="mt-8"
          />
        )}
      </div>
    </div>
  );
};
