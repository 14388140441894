import { Content } from "../types";
import gallery1 from "../../assets/img/gallery/gallery1.jpeg";
import gallery2 from "../../assets/img/gallery/gallery2.jpeg";
import gallery3 from "../../assets/img/gallery/gallery3.jpeg";
import gallery4 from "../../assets/img/gallery/gallery4.jpeg";
import gallery5 from "../../assets/img/gallery/gallery5.jpeg";
import gallery6 from "../../assets/img/gallery/gallery6.jpeg";
import gallery8 from "../../assets/img/gallery/gallery8.jpeg";
import gallery9 from "../../assets/img/gallery/gallery9.jpeg";
import exCenter from "../../assets/img/gallery/center/ex_center1.jpeg";
import inCenter1 from "../../assets/img/gallery/center/in_center1.jpeg";
import inCenter2 from "../../assets/img/gallery/center/in_center2.jpeg";
import inCenter3 from "../../assets/img/gallery/center/in_center3.jpeg";
import inCenter4 from "../../assets/img/gallery/center/in_center4.jpeg";
import inCenter5 from "../../assets/img/gallery/center/in_center5.jpeg";
import inCenter6 from "../../assets/img/gallery/center/in_center6.jpeg";

export const galleries: Content[] = [
  {
    id: 3,
    title: "정신분석 대가 김병훈 교수의 / 가족치료",
    description: "",
    imageUrl: gallery2,
    createdAt: "2019-06-16 20:27:58",
  },
  {
    id: 2,
    title: "상담실",
    description: `
      <img src="${inCenter1}" alt="in_center1" style="margin-bottom: 10px" />
      <img src="${inCenter2}" alt="in_center2" style="margin-bottom: 10px" />
      <img src="${inCenter3}" alt="in_center3" style="margin-bottom: 10px" />
      <img src="${inCenter4}" alt="in_center4"  style="margin-bottom: 10px"/>
      <img src="${inCenter5}" alt="in_center5" style="margin-bottom: 10px"/>
      <img src="${inCenter6}" alt="in_center6" style="margin-bottom: 10px" />
    `,
    imageUrl: gallery1,
    createdAt: "2019-05-16 18:41:31",
  },
  {
    id: 1,
    title: "상담소",
    description: "",
    imageUrl: exCenter,
    createdAt: "2019-05-16 18:41:31",
  },
  {
    id: 5,
    title: "화성시 지원 / 자존감 회복프로그램",
    description: "",
    imageUrl: gallery4,
    createdAt: "2019-06-16 20:33:31",
  },
  {
    id: 4,
    title: "정신분석대가 김병훈 교수의  / 가족치료",
    description: "",
    imageUrl: gallery3,
    createdAt: "2019-06-16 20:28:53",
  },
  {
    id: 6,
    title: "화성시 지원/ 자존감 회복 프로그램",
    description: "",
    imageUrl: gallery5,
    createdAt: "2019-06-16 20:34:13",
  },
  {
    id: 7,
    title: "각종프로그램",
    description: "",
    imageUrl: gallery6,
    createdAt: "2019-06-16 20:35:49",
  },
  {
    id: 9,
    title: "부모교육 프로그램 1기 선생님들과 함께",
    description:
      "<p>저희 기관에서 지역주민들을 위해<br />무료 공개 강좌를 실시합니다.<br />매주 금요일 오전 10:00-12:00(2개월과정)이예요.</p><p>동탄에 계신 분들 어서오세요~<br />그 어느 곳보다 편하게 오셔서 부담없이 들으실 수 있을거예요^^</p>",
    imageUrl: gallery9,
    createdAt: "2019-07-04 17:37:34",
  },
  {
    id: 8,
    title: "아동 놀이치료 및 집단상담",
    description: "",
    imageUrl: gallery8,
    createdAt: "2019-06-19 16:23:39",
  },
];
