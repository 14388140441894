import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const PageNotFound: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.warning("찾을 수 없는 페이지입니다.");
    navigate("/");
  }, [navigate]);

  return <div />;
};
