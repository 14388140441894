import React from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";

const centerCoords = { lat: 37.2082991856285, lng: 127.062926870641 };

const WayToCome: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-col mx-auto lg:px-20 px-4 mt-10 lg:w-web w-full">
      <h3 className="py-6 font-semibold text-lg lg:pt-12 lg:text-xl">
        오시는 길
      </h3>
      <div className="flex justify-center items-center">
        <Map level={3} center={centerCoords} className="w-full lg:h-400 h-310">
          <MapMarker position={centerCoords} />
        </Map>
      </div>
      <div className="w-full h-24" />
    </div>
  );
};

export default WayToCome;
