import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { galleries } from "../../../data/gallery";
import { toast } from "react-toastify";
import { Content } from "../../../data/types";

export const GalleryDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState<Content | null>(null);

  useEffect(() => {
    const newGallery = galleries.find((gallery) => id && gallery.id === +id);
    if (!newGallery) {
      toast.warning("찾을 수 없는 페이지입니다.");
      navigate("/");
      return;
    }

    setGallery(newGallery);
  }, [navigate, id]);

  if (!gallery) {
    return null;
  }

  return (
    <div className="flex justify-center my-16">
      <div className="flex items-center flex-col lg:w-web px-4 lg:px-0">
        <h1 className="font-bold text-2xl mb-8">{gallery.title}</h1>
        <img src={gallery.imageUrl} alt="gallery" />
        {gallery.description && (
          <p
            dangerouslySetInnerHTML={{ __html: gallery.description }}
            className="mt-8"
          />
        )}
      </div>
    </div>
  );
};
