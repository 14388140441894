import React from "react";
import { ListCard } from "../../components/ListCard";
import { interviews } from "../../data/interviews";

export const Interviews: React.FC = () => {
  return (
    <div className="w-full flex items-center flex-col relative pt-8 pb-8">
      <ListCard link="interview" cards={interviews} />
    </div>
  );
};
