import React, { useCallback, useEffect, useMemo, useState } from "react";
import blogTypeCard from "../../data/blogTypeCards";
import { ListCard } from "../../components/ListCard";
import { blogs as allBlogs } from "../../data/blogs";
import { BlogType, Content } from "../../data/types";
import Pagination from "../../shared/components/Pagination";

export const Blogs: React.FC = () => {
  const [type, setType] = useState<BlogType>("talk");
  const [page, setPage] = useState(1);
  const [blogs, setBlogs] = useState<Content[]>([]);

  const filteredBlogs = useMemo(
    () =>
      allBlogs.filter((blog) => blog.type === type).sort((a, b) => b.id - a.id),
    [type]
  );

  const handleBlogTypeChange = useCallback((value: BlogType) => {
    setPage(1);
    setType(value);
  }, []);

  useEffect(() => {
    setBlogs(filteredBlogs.slice(0, page * 12));
  }, [filteredBlogs, page]);

  return (
    <div className="w-full flex items-center flex-col relative">
      <div
        className={`w-full mt-4 mb-8 bg-white flex-shrink-0 lg:w-1/2 grid grid-cols-3 gap-x-2 px-2`}
      >
        {blogTypeCard.map((card) => (
          <div
            className="cursor-pointer lg:mr-2 shadow-2xl items-center flex
           shadow-xl opacity-75 w-full h-16 lg:h-36 justify-center"
            onClick={() => {
              handleBlogTypeChange(card.type);
            }}
            style={{
              backgroundImage: `url(${card.imageUrl})`,
            }}
            key={`talk-type-card-${card.id}`}
          >
            <h1 className="lg:text-2xl text-lg font-bold text-white">
              {card.title}
            </h1>
          </div>
        ))}
      </div>
      <ListCard link="blog" cards={blogs} />
      <Pagination
        hasMorePage={filteredBlogs.length !== blogs.length}
        onClick={() => {
          setPage(page + 1);
        }}
      />
    </div>
  );
};
