import Private from "../assets/icon/private.png";
import Couple from "../assets/icon/couple.png";
import Family from "../assets/icon/family.png";
import Child from "../assets/icon/child.png";
import Group from "../assets/icon/group.png";
import Play from "../assets/icon/play.png";
import Parent from "../assets/icon/parent.png";
import Clay from "../assets/icon/clay.png";
import Language from "../assets/icon/language.png";
import Book from "../assets/icon/book.png";

export interface Field {
  id: number;
  name: string;
  img: string;
  content: string;
}

const fields: Field[] = [
  {
    id: 0,
    name: "개인상담",
    img: Private,
    content: `
            급변하는 사회환경 속에서 다양하고도 복잡한 관계를 살아가고 있는 현대인들은 점점 가치관과 정체성을 잃어 갈 뿐 아니라 
            순간의 좌절과 무력감 속에서 우울하고 공허한 일상생활을 보내고 있습니다. <br><br>
            보다 가치있고 행복한 삶을 살고 싶지만 진정한 삶의 의미가 무엇인지 혼란스럽기만 합니다.<br><br>
            믿었던 가족, 배우자, 친구가 때론 상처가 되고 주변사람들과의 관계도 힘들고 고통스러울 때 
            상담자와 손잡고 함께 걸어간다면 훨씬 더 편안하고 행복한 인생길로 나아가게 됩니다.
        `,
  },

  {
    id: 1,
    name: "부부상담",
    img: Couple,
    content: `
            각기 다른 가족문화 속에서 자라 한 가정을 이루고 살아가다보면 그 안에는 사랑도 있지만 갈등도 함께 자라가게 됩니다.<br><br>
            부부 관계는 생애 초기의 부모관계는 물론이고 부모와 자녀와의 관계와 관련되어 있습니다.<br><br>
            그러한 초기 경험에서 비롯된 감정과 행동양식은 가족 상호간 서로 관계하는 방식에 많은 영향을 미치며 
            이런 관계방식이 가족의 발달을 촉진하거나 방해하기도 합니다.<br><br>
            따라서 가족의 갈등을 풀어가기란 마음처럼 쉽지가 않고 전문가와 함께 풀어가는 것이 좋습니다.
        `,
  },
  {
    id: 2,
    name: "가족상담",
    img: Family,
    content: `
            개인의 심리적 장애와 문제는 가족간의 부적응으로 인해 생겨나며 
            최초의 원가족에서의 상호작용으로 인해 정서적 건강에도 많은 영향을 받을 수 있습니다.<br><br>
            부모 자식간, 형제간 분화가 필요한데 가족간의 분화가 안된 경우 사회생활은 물론 결혼생활도 힘들어지게 됩니다.<br><br>
            Bowen(1976)의 가족체계이론에서 나온 개념인 자기분화는 개인의 사고와 정서를 분리시킬 수 있는 능력이며, 
            정서적 성숙 정도와 함께 자기가 태어난 가정으로부터 개성화된 정도를 말하는 것으로 
            가족들이 분화되어 법을 지키며 자기 삶을 잘 살아갈 때 혼돈과 갈등은 줄고 질서 있는 삶을 살아갈 수 있게 됩니다.
        `,
  },
  {
    id: 3,
    name: "아동, 청소년상담",
    img: Child,
    content: `
            자라나는 아동, 청소년들은 미래 우리사회의 주인이 될 소중한 자녀들입니다. 
            어떤 환경에서 자랐는가에 따라서 씩씩하게 학교생활에 적응하며 나아 갈 수도 있지만 
            자존감이 낮아 부적응자로 살아갈 수도 있습니다.<br><br>
            초기 학교생활은 매우 중요한데 자칫 잘못된 인생구조가 될 수 있기 때문입니다. 
            심리치료를 통하여 자신감 있는 학교생활은 물론, 사회로 나아가 성공하며 살아갈 수 있는 
            자녀로 성장시키기 위한 준비를 하면 많은 도움이 될 것 입니다.<br><br>
            몸은 자라가는데 정신성장이 되지 않으면 어른이 되어서도 아이 같은 삶을 살아갑니다.
            정신성장은 매우 중요한 것으로 몸도 마음도 잘 성장하여 사회의 주역으로 키워야 할 책임이 어른들에게 있습니다.
        `,
  },
  {
    id: 4,
    name: "집단상담",
    img: Group,
    content: `
            집단 상담은 1:1의 상담이 아닌 여러 명의 내담자들로 구성되며, 
            일정기간 정기적인 상담 과정을 통해 서로의 감정과 행동, 생각을 역동적으로 교환해 가면서 문제를 해결해 갑니다.<br><br>
            이자관계와는 달리 여러명이 함께 대화를 나누며 관계하므로 
            정서적으로 더 많은 경험을 하게 되며 개인의 이슈들이 나오게 됩니다.<br><br>
            그 이슈들을 작은 집단에서 경험하며 풀어갈 때 사회구성원으로서 무난한 대상관계를 하며 
            살아가게 되며 보다 나은 정신건강의 증진과 개인의 발달을 돕는 상담입니다.
        `,
  },
  {
    id: 5,
    name: "놀이치료",
    img: Play,
    content: `
            잘 놀 줄 아는 아이가 건강합니다. 아이들에게 있어서 '놀이'는 자신이 느끼고 
            생각하는 것들을 충분히 표현하고 탐색하게 해주는 자연스러운 통로입니다.<br><br>
            어른들이 대화나 이야기를 하면서 고민을 털어내듯,
             아이들은 놀이를 통해서 자신의 감정과 어려움을 표현하며 드러낼 수 있습니다.<br><br>
            그러므로 미술치료와 점토치료 등 종합예술치료를 통하여 아이들 내면세계에 있는 불편감정들을 해결합니다.
        `,
  },
  {
    id: 6,
    name: "부모교육",
    img: Parent,
    content: `
            자녀들은 부모의 거울입니다. 내 자녀를 보면 내가 어떤 사람인지 알 수 있습니다. 
            부모가 어떻게 사느냐에 따라서 자녀들의 삶이 달라질 수 밖에 없습니다.<br><br>
            그런데 잘 산다는 것이 그렇게 쉽지만은 않지요. 
            때론 갈등하며 속상해하고, 다투기도 하고, 사랑하는 자녀를 꾸짖고나면 마음이 아프기도 하지요.<br><br>
            우리 아이 어떻게하면 잘 키울까요? 혼자 고민하지 마십시오. 
            함께 고민하며 해결해 보자구요. 내가 바뀌면 자녀도 바뀌게 된답니다.
        `,
  },
  {
    id: 7,
    name: "점토치료",
    img: Clay,
    content: `
            '삶을 살 만한 가치가 있다고 느끼게 만드는 것은
            무엇보다도 창조적인 통각이다.'<br>
            - 도널드 위니캇<br><br>
            
            점토치료는 창조적인 통각을 발달시키기에 적합한 활동 중 하나입니다. <br>
            점토를 빚으며 무언가를 만드는 그 과정과 마침내 만들어낸 창조물 속에서, 
            전에는 몰랐던 나의 특별한 가치를 발견할 수 있습니다.<br><br>
            
            그 가치는 아동부터 성인까지 누구에게나 다시 한 번 새롭게 시작할 수 있는 기회를 줍니다.<br><br>
            
            점토치료를 통해 '흙역사'를 '영웅신화'로 바꿔보세요!
        `,
  },
  {
    id: 8,
    name: "언어치료",
    img: Language,
    content: `
            아이들에게 있어서 언어란 대상관계에 있어 매우 중요합니다. 
            하지만 마음대로 잘 되지 않을 땐 전문가의 도움을 빨리 받아보는 것이 좋겠습니다.
            시기를 늦추게 되면 자존감도 떨어지고 더 큰 문제로 발전 할 수 있게 되기 때문입니다.<br><br>
            우리 아이가 언어발달지체 및 장애, 단순 언어 발달장애(SLI), 
            조음음운장애(발음), 유창성장애(말더듬)이 있다고 생각되시면 
            저희 기관에서 '언어치료 & 심리치료' 전문가(박사) 선생님께 상담을 의뢰하시면 된답니다.<br><br>
            치료시간은 40분이며 필요에 따라 부모상담 5-10분 추가 진행합니다.
        `,
  },
  {
    id: 9,
    name: "독서치료",
    img: Book,
    content: `
            현대사회는 빠르게 변화하고 우리는 크고 작은 문제로 갈등하고 힘들어합니다.<br><br>
            내면의 흔들리는 갈등을 잡아주고 지금 처한 문제를 새롭게 해석할 뿐 아니라 
            회복과 성장으로 갈 수 있도록 심리, 정신분석관련 책으로 함께 나누는 프로그램입니다.<br><br>
            진리는 우리를 자유케 한다는 말처럼 언어와 지식의 힘은 우리의 인식체계를 변화시킵니다. 
            인문학을 통해 우리의 삶이 창조와 승리로 나갈 수 있도록 함께 공부하길 바랍니다.
        `,
  },
];

export default fields;
